import ResponsiveImage from '@/components/ResponsiveImage.vue';
export default defineComponent({
  components: {
    ResponsiveImage: ResponsiveImage
  },
  layout: 'unauthenticated',
  head: function head() {
    return {
      title: 'Password Reset - Mail Sent'
    };
  }
});